import React from 'react'
import { Link } from 'gatsby'

import logo from '../../static/img/radentscheid_logo_white.svg'
import facebook from '../img/social/facebook.svg'
import instagram from '../img/social/instagram.svg'
import twitter from '../img/social/twitter.svg'
import NewsletterSignUp from './NewsletterSignUp';

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-overlay has-overlay-full" style={{ padding: '10em 0' }}>
        <div className="content container has-text-centered">
          <img
            src={logo}
            alt="Radentscheid Bayreuth"
            style={{ width: '14em' }}
          />
        </div>
        <div className="">
          <div className="container">
            <div className="columns">
              <div className="column is-4">
                <section className="menu">
                  <h2 className="title is-4 has-text-white">Information</h2>
                  <ul className="menu-list">
                    <li>
                      <Link to="/" className="navbar-item">
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link to="/forderungen" className="navbar-item">
                        Forderungen
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/unterschreiben/#sammelstellen">Sammelstellen</Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/blog">
                        Aktuelles
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/faq">FAQ</Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/presse">Presse</Link>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column is-4">
                <h2 className="title is-4 has-text-white">Mitgestalten!</h2>
                <ul className="menu-list">
                  <li>
                    <Link className="navbar-item" to="/unterschreiben">Unterschreiben</Link>
                  </li>
                  <li>
                    <Link className="navbar-item" to="/kontakt">
                      Kontakt
                      </Link>
                  </li>
                  <li>
                    <Link className="navbar-item" to="/mitmachen">Mitmachen</Link>
                  </li>
                </ul>
              </div>
              <div className="column is-4">
                <h2 className="title is-4 has-text-white">In Kontakt bleiben!</h2>
                <p class="has-text-white subtitle is-6">Bleib auf dem Laufenden - hier bekommst du Updates zu weiteren Treffen und zum aktuellen Projektfortschritt.</p>
                <h2 className="title is-5 has-text-white" style={{ marginTop: '1.25rem', marginBottom: '.5em' }}>Newsletter</h2>
                <NewsletterSignUp className="has-text-white" />
                <h2 className="title is-5 has-text-white" style={{ marginTop: '1.25rem', marginBottom: '.5em' }}>Social Media</h2>
                <div className="social">
                  <a title="facebook" href="https://www.facebook.com/radentscheidbt/">
                    <img
                      src={facebook}
                      alt="Facebook"
                      style={{ width: '2em', height: '2em' }}
                    />
                  </a>
                  <a title="instagram" href="https://www.instagram.com/radentscheid_bt/">
                    <img
                      src={instagram}
                      alt="Instagram"
                      style={{ width: '2em', height: '2em' }}
                    />
                  </a>
                  <a title="twitter" href="https://twitter.com/RadentscheidBT">
                    <img
                      src={twitter}
                      alt="Twitter"
                      style={{ width: '2em', height: '2em' }}
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="columns">

            </div>
            <div className="columns">
              <div className="column has-text-white">
                <p>Radentscheid Bayreuth. Das Bürgerbegehren für eine lebenswertere Stadt.</p>
              </div>
              <div className="column has-text-white has-text-right has-text-left-mobile">
                <p><Link className="has-text-white" to="/datenschutz">Datenschutz</Link> | <Link className="has-text-white" to="/impressum">Impressum</Link></p>

              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
