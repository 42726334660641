import React from 'react'

const html = (className) => {
    var string = ` <!-- Begin Mailchimp Signup Form -->
    <div id="mc_embed_signup">
    <form action="https://radentscheid-bayreuth.us4.list-manage.com/subscribe/post?u=c87c2343a6a68095cfc79b809&amp;id=133fa6df4b" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
        <div id="mc_embed_signup_scroll">
                <div class="mc-field-group field has-addons">
                    <div class="control">
                        
                        <input type="email" value="" placeholder="Email-Adresse" name="EMAIL" class="required input email" id="mce-EMAIL">
                    </div>
                    <div class="control">
                        
                            <input type="submit" value="Anmelden" name="subscribe" id="mc-embedded-subscribe" class="button"></div>
                        

                    </div>
                </div>
                <div id="mce-responses" class="clear">
                    <div class="response" id="mce-error-response" style="display:none"></div>
                        <div class="response" id="mce-success-response" style="display:none"></div>
                </div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                <div style="position: absolute; left: -5000px;" aria-hidden="true">
                    <input type="text" name="b_c87c2343a6a68095cfc79b809_133fa6df4b" tabindex="-1" value=""></div>
                </div>
    </form>
    </div>
    
    <!--End mc_embed_signup-->`
    return string
}

class NewsletterSignUp extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            email: ""
        }
    }

    __handleChange = e => {
        this.setState()
    }

    handleSubmit = e => {
        e.preventDefault()
        fetch()
            .catch(error => alert(error))
    }

    render() {
        return (
            <div id="mc_embed_signup" className="{this.props.className}">
                <div dangerouslySetInnerHTML={{ __html: html(this.props.className) }} />
            </div>
        )
    }
}

export default NewsletterSignUp